import React, { Component } from 'react'


export default class page5 extends Component {

    componentDidMount() {
        setTimeout(function(){
            document.querySelector(".splash").classList.add("seen");
            }, 1500);
    }

  render() {
    return (
      <div>
       
      </div>
    )
  }
}
